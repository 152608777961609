@import (reference) 'partials/variables';

.confirm-email {
  .details {
    font-size: 14px;
  }
  .description{
    font-size: 14px;
    text-align: center;
    line-height: 1.29;
    color: @limed_spruce;
    padding-bottom: 19px;
  }
}

.form-sign-in {
  width: 100%;
  height: auto;
  // todo: developer bookmark
  // height: 100%;
  // min-height: 600px;
  // overflow: auto;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  overflow-y: visible;

  .left {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    text-align: left;
  }

  .right {
    align-items: stretch;
    justify-content: stretch;
  }

  .logo {
    text-align: center;
  }

  .content {
    width: 320px;
    margin-top: 23px;
    border-radius: 4px;
    background: @white_deep;
    padding: 10px 32px 10px 32px;
    overflow: hidden;

    h1 {
      text-align: center;
      line-height: 1.4;
      margin: 14px -10px 16px;
    }
  }

  input[type='text'],
  input[type='email'],
  input[type='password'] {
    width: 100%;
    height: 40px;
    padding: 11px 10px 13px;
    margin: 8px 0 16px;
    &::placeholder {
      line-height: inherit;
    }
  }

  .form-content {
    clear: both;
    padding-top: 20px;
  }

  .btn-l {
    width: 100%;
    height: 40px;
    display: block;
    cursor: pointer;
    padding: 0;
  }

  ul {
    margin: 0 0 21px 15px;
    padding: 0;
    color: @sonic_silver;
    font-size: 12px;
    line-height: 1.34;
  }

  .form-group + ul {
    margin-top: -16px;
  }

  .input-wrapp {
    position: relative;
  }

  .btn-login-guest {
    margin: 10px 0 2px;
  }

  .btn-why-signup {
    letter-spacing: initial;
    font-weight: bold;
    font-size: 14px;
    background-color: transparent;
    border: 0;
    text-align: center;
    width: 100%;
    margin-top: 24px;
  }

}


@media (min-width: 401px) {
  .form-sign-in {
    .left {
      margin-top: 57px;
    }
  }
}

@media (min-width: 768px) {
  .form-sign-in {
    .right {
      flex-grow: 1;
      padding-bottom: 0;
      .btn-login-guest {
        margin: 10px 0 2px;
      }
    }

    .content {
      flex: none;
      width: 320px;
      border: 1px solid @pale_grey;
      padding-bottom: 24px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .form-upgrade-version {
    .right {
      flex-grow: unset;
    }
  }
}

@media (min-width: 1024px) {
  .form-sign-in {
    flex-direction: row;
    overflow-y: scroll;
    height: 100%;

    .left {
      width: 440px;
      max-width: 440px;
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
      app-auth-footer {
        margin-top: auto;
      }
    }
    .right {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 440px;
      padding-bottom: 0;
      app-auth-footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 33px 73px 0 112px;
        .auth-footer {
          max-width: 656px;
        }
      }
    }

    &.form-upgrade-version {
      .logo {
        text-align: left;
      }
      .auth-footer-left {
        padding-left: 72px;
      }
    }
  }
}
