@import (reference) 'partials/variables';

* {
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5 {
  margin-bottom: 8px;

  &.bottom-border {
    border-bottom: 1px solid @pale_grey;
  }
}

h1 {
  font-size: 40px;
  font-weight: 300;
  line-height: 40px;
  color: @limed_spruce_dark;
  text-align: center;
}

h2 {
  font-size: 32px;
  font-weight: 400;
  color: @limed_spruce;
  padding-bottom: 8px;
}

h3 {
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
}

h4 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.78;
  color: @limed_spruce;
  padding-bottom: 8px;
}

.break-word {
  word-wrap: break-word;
}

.text-error {
  padding-left: 12px;
  font-size: 14px;
  color: @error-text;
}

.text-muted {
  color: @sonic_silver !important;
}
