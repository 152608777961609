.flex-container {
  display: flex;
}

.flex-row {
  flex-flow: row nowrap;
  align-items: center;
}

.flex-column {
  flex-flow: column nowrap;
  justify-content: stretch;
}

.flex-justify {
  justify-content: space-between;
}

.flex-split {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
}

.flex-column-align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}