@import 'variables';

input[type='text'],
input[type='email'],
input[type='password'] {
  margin: 16px 0;

  &.touch-friendly {
    padding: 12px 10px;
  }
}

input[type=radio] {
  position: absolute;
  visibility: hidden;
}

label.radio-label {
  position: relative;
  padding-left: 24px;
  &.btn {
    text-transform: inherit;
    font-weight: inherit;
    background: inherit;
    color: inherit;
    padding: 0;
    padding-left: 24px;
  }
  &::before {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 100%;
    position: absolute;
    left: 0;
    bottom: 1px;
  }
  &.checked::after,
  &.active::after {
    content: '';
    position: absolute;
    left: 4px;
    bottom: 5px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
  }
}

input[type='text'],
input[type='password'],
input[type='date'],
input[type='email'],
input[type='number'],
input[type='search'],
input[type='tel'],
textarea,
select {
  border: 1px solid @water_grey;
  border-radius: 4px;
  padding: 5px 10px 5px;
  background-color: white;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  height: 32px;

  &:hover {
    border: 1px solid @black_opacity_015;
  }

  &:focus, &:active {
    border: 1px solid;
  }

  &:disabled {
    border: none;
    background-color: @water_grey_opacity_04;
  }

  &::placeholder {
    color: @sonic_silver;
  }
  &::-webkit-input-placeholder {
    color: @sonic_silver;
  }
  &::-moz-input-placeholder {
    color: @sonic_silver;
  }
  &:-moz-input-placeholder {
    color: @sonic_silver;
  }
  &:-ms-input-placeholder {
    color: @sonic_silver;
  }
}

select {
  padding: 4px 25px 4px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Stylized select
.stylized-select {
  position: relative;
  cursor: pointer;

  select {
    position: relative;
    z-index: 2;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    background-image: none;

    &::-ms-expand {
        display: none;
    }
  }

  .select-arrow {
    z-index: 1;
    position: absolute;
    display: block;
    top: 0.4375em;
    right: 0.35em;
    width: 20px;
    height: 20px;
  }
}

form {
  .form-group {
    label {
      color: @sonic_silver;
      font-size: 12px;
      margin: 0;
      &+.form-control {
        margin: 5px 0 0;
      }
    }
    .password-toggle {
      width: 18px;
      height: 18px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: absolute;
      right: 10px;
      top: 28px;
      cursor: pointer;
      &.on {
        background-image: url("/assets/icons/ic-eye-on.svg");
      }
      &.off {
        background-image: url("/assets/icons/ic-eye-off.svg");
      }
    }
    &.no-label {
      .password-toggle {
          top: 19px;
      }
    }
  }
  .form-text {
    .small {
      font-size: 85%;
    }
    &+.form-text {
      margin-top: 0;
    }
  }
  .error-message {
    margin-bottom: 0;
  }

  .input-control {
    margin-bottom: 20px;

    input {
      margin: 0;
    }
  }

  .input-label {
    /* Display & Box Model */
    display: block;
    margin-bottom: 5px;
    /* Color */
    color: @warm-grey;
    /* Text */
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;

    &.required {
      padding-left: 3px;
      color: @black_pearl;
      &:before {
        position: relative;
        top: 0;
        left: -3px;
        color: @error-text;
        content: '*';
      }
    }
    .sub-input-label {
      font-weight: normal;
    }
  }

  .required {
    padding-left: 3px;
    &:before {
      position: relative;
      top: 0;
      left: -3px;
      color: @error-text;
      content: '*';
    }
  }

  .input-error {
    margin-left: 10px;
    font-size: 12px;
    line-height: 14px;
    color: @error-text;
  }
}
