:root {
  --theme-accent-color: rgb(255, 204, 0);
  --theme-primary-color: rgb(58, 191, 188);
  --theme-secondary-color: rgb(38, 144, 229);

  --theme-accent-color-darken-10: rgb(204, 163, 0);
  --theme-primary-color-darken-10: rgb(47, 152, 150);
  --theme-secondary-color-darken-10: rgb(22, 116, 192);

  --theme-accent-color-darken-15: rgb(179, 143, 0);
  --theme-primary-color-darken-15: rgb(41, 133, 131);
  --theme-secondary-color-darken-15: rgb(20, 102, 169);

  --theme-accent-color-darken-25: rgb(128, 102, 0);
  --theme-primary-color-darken-25: rgb(29, 94, 93);
  --theme-secondary-color-darken-25: rgb(14, 74, 123);

  --theme-accent-color-darken-35: rgb(77, 61, 0);
  --theme-primary-color-darken-35: rgb(17, 55, 54);
  --theme-secondary-color-darken-35: rgb(9, 47, 78);

  --theme-accent-color-opacity-10: rgba(255, 204, 0, 0.1);
  --theme-primary-color-opacity-10: rgba(58, 191, 188, 0.1);
  --theme-secondary-color-opacity-10: rgba(38, 144, 229, 0.1);

  --theme-accent-color-opacity-24: rgba(255, 204, 0, 0.24);
  --theme-primary-color-opacity-24: rgba(58, 191, 188, 0.24);
  --theme-secondary-color-opacity-24: rgba(38, 144, 229, 0.24);

  --theme-accent-color-opacity-35: rgba(255, 204, 0, 0.35);
  --theme-primary-color-opacity-35: rgba(58, 191, 188, 0.35);
  --theme-secondary-color-opacity-35: rgba(38, 144, 229, 0.35);

  --theme-accent-color-opacity-40: rgba(255, 204, 0, 0.4);
  --theme-primary-color-opacity-40: rgba(58, 191, 188, 0.4);
  --theme-secondary-color-opacity-40: rgba(38, 144, 229, 0.4);
}

@error-text: #ff5531;
@alert_orange: #ffab00;
@info_blue: #2690E5;
@success_green: #66bb6a;
@require_red: #eb6c6c;
@custom_secondary: #45b7b7;

@weak_silver: #B2B2B2;
@faux_bright_gray: #EDEEF2;
@limed_spruce: #353535;
@limed_spruce_dark: #343434;
@picton_blue: #2690e5;
@puerto_rico: #3bc1bf;
@light_blue: #45b7b762;
@wild_sand: #f7f7f7;
@water_grey: #d1d5de;
@pale_grey: #e6e8ed;
@color_jet: #353535;
@supernova_yellow: #ffcc00;
@light-yellow: #FFFAE6;
@light-orange: #FFEDE0;
@light_grey_yellow: #edebe3;
@black_pearl: #030821;
@pale_turquoise: #B2EBF2;
@cobalt_blue: #005395;
@schiava_blue: #192A69;
@corn_silk: #fff9e5;
@bondi_blue: #00a2ad;
@pale_sky: #626E86;
@warm-grey: #7b7b7b;
@porcelain: #f5f6f7;
@soft_grey: #c4c4c4;
@white: #ffffff;

@badge_yellow: #fff5bd;

@sonic_silver: rgb(117, 117, 117); // #757575
@sonic_silver_opacity_05: rgba(117, 117, 117, 0.5);

@pale_grey_opacity_04: rgba(230, 232, 237, .4);
@pale_grey_opacity_03: rgba(230, 232, 237, .3);
@pale_grey_opacity_05: rgba(230, 232, 237, .5);
@pale_grey_opacity_06: rgba(230, 232, 237, .6);
@pale_grey_opacity_09: rgba(230, 232, 237, .9);

@limed_spruce_opacity_0: rgba(53, 53, 53, 0);
@limed_spruce_opacity_01: rgba(53, 53, 53, .1);
@limed_spruce_opacity_015: rgba(53, 53, 53, .15);
@limed_spruce_opacity_02: rgba(53, 53, 53, .2);
@limed_spruce_opacity_03: rgba(53, 53, 53, .3);
@limed_spruce_opacity_045: rgba(53, 53, 53, .45);
@limed_spruce_opacity_05: rgba(53, 53, 53, .5);
@limed_spruce_opacity_07: rgba(53, 53, 53, .7);
@limed_spruce_opacity_075: rgba(53, 53, 53, .75);
@limed_spruce_opacity_08: rgba(53, 53, 53, .8);
@limed_spruce_opacity_100: rgba(53, 53, 53, 1);

@black_pearl_opacity_012: rgba(3, 8, 33, .12);
@black_pearl_opacity_02: rgba(3, 8, 33, .2);
@black_pearl_opacity_04: rgba(3, 8, 33, .4);

@cobalt_blue_opacity_016: rgba(0, 83, 149, .16);

@warm_grey_opacity_02: rgba(123, 123, 123, .2);
@warm_grey_opacity_06: rgba(123, 123, 123, .6);

@water_grey_opacity_02: rgba(209, 213, 222, .2);
@water_grey_opacity_04: rgba(209, 213, 222, .4);

@pale_sky_opacity_005: rgba(98, 110, 134, .05);

@black_deep: #000;
@black_transparent: rgba(0, 0, 0, 0);
@black_opacity_004: rgba(0, 0, 0, 0.04);
@black_opacity_01: rgba(0,0,0, .1);
@black_opacity_02: rgba(0,0,0, .2);
@black_opacity_025: rgba(0,0,0, .25);
@black_opacity_014: rgba(0, 0, 0, 0.14);
@black_opacity_015: rgba(0,0,0, .15);
@black_opacity_03: rgba(0, 0, 0, .3);
@black_opacity_07: rgba(0, 0, 0, .7);
@black_opacity_075: rgba(0, 0, 0, .75);
@black_opacity_08: rgba(0, 0, 0, .8);

@white_deep: #fff;
@white_transparent: rgba(255, 255, 255, 0);
@white_opacity_02:  rgba(255, 255, 255, .2);
@white_opacity_05:  rgba(255, 255, 255, .5);
@white_opacity_07:  rgba(255, 255, 255, .7);
@white_opacity_08:  rgba(255, 255, 255, .8);

@sonic_silver_opacity_3: rgba(75 ,75 ,75 ,.3);
@porcelain: rgba(245, 246, 247, 1);

// Media breakpoints
@xs_mobile: ~"( max-width: 420px )";
@xs_scrollable_mobile: ~"( min-width: 540px )";
@xs_extra_mobile: ~"( min-width: 576px )";
@tablet_and_desktop: ~"( min-width: 768px )";
@landscape_and_desktop: ~"( min-width: 1024px )";
@desktop: ~"( min-width: 1280px )";

// Media breakpoints with max-width. We attempt to never use these, but may be used if necessary. It's probably not necessary.
@mobile_extra_exception_case: ~"( max-width: 539px )";
@mobile_exception_case: ~"( max-width: 767px )";
@tablet_exception_case: ~"( min-width: 768px ) and (max-width: 1279px)";
@middle_exception_case: ~"( min-width: 1024px ) and (max-width: 1279px)";
@tablet_and_desktop_exception_case: ~"( min-width: 992px )";
@desktop_exception_case: ~"( min-width: 1024px ) and (max-width: 1471px)";
@landscape_and_desktop_exception_case: ~"( max-width: 1023px )";

// Media breakpoint for new wide content
@wide_desktop: ~"(min-width: 1472px)";
@xl_desktop: ~"( min-width: 1920px )";

@window_x_small_max_size: 460px;
@window_x_extra_small_max_size: 500px;
@window_small_max_size: 700px;
@window_medium_max_size: 944px;
@window_large_max_size: 1136px;
