// Widgets
.widget {
  border-radius: 4px;
  background-color: @wild_sand;
  padding: 24px;

  h3 {
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: @limed_spruce;
    margin: 0;
  }

  h5 {
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: @limed_spruce;
    margin: 0;
  }

  div.content {
    margin-top: 20px;
  }

  .flex-column {
    margin: -8px;
  }

  .correct-margin {
    margin: -8px;
  }

  .flex-container > * {
    margin: 8px;
  }

  input,
  button,
  select {
    width: 100%;
  }

  .btn {
    margin: 0;
    width: 100%;
    min-height: 32px;
    font-size: 14px;
    padding: 5px;
  }

  .logo-wrapper {
    text-align: right;
    width: 100%;
    margin-top: 27px;
  }
}

.widget-b {
  background-color: @wild_sand;
  border: solid 1px @pale_grey;
}

@media screen and (min-width: 768px) {
  .widget {
    height: 220px;
  }

  .widget-b {
    height: 440px;
  }
}
