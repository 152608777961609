html,
body {
  height: 100%;
  position: relative;
}

html, body, div, span, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, em, img, ins, kbd, q,
small, strong, sub, sup, b, u, i, center,
dl, dt, dd, ol, ul, li, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, menu, nav {
  margin: 0;
  padding: 0;
  border: 0;
}

p {
  margin: 0 0 1rem 0;
  padding: 0;
  border: 0;
}

body {
  :focus,
  .btn:focus,
  button.btn:focus {
    outline: none;
    box-shadow: none;
  }

  &.user-is-tabbing {
    :focus,
    .btn:focus,
    button.btn:focus {
      outline: #6aa3f7 auto 5px;
      box-shadow: 0 0 3px 0.6px #3183fd;
    }
  }
}

// * Bootstrap 5 overrides
a {
  text-decoration: none;
}

button {
  background: none;
  border: none;
}

.small {
  font-size: .8em;
}

.modal-content {
  border-radius: .3rem;
}

.btn {
  border-radius: .25rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-label {
  margin-bottom: 0;
}

.placeholder {
  background-color: @white_deep;
}