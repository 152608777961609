.no-save-offers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-top: 1px solid @pale_grey;
  border-bottom: 1px solid @pale_grey;
  padding-top: 62px;
  padding-bottom: 69px;

  p {
    margin-top: 13px;
    color: @sonic_silver;
    max-width: 260px;

    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
  }
}
