
@import (reference) 'variables';

@keyframes remove {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }

  25% {
    transform: translate(15%, 0);
    opacity: 0.75;
  }

  50% {
    transform: translate(30%, 0);
    opacity: 0.5;
  }

  75% {
    transform: translate(45%, 0);
    opacity: 0.25;
  }

  100% {
    transform: translate(60%, 0);
    opacity: 0;
  }
}

@-webkit-keyframes remove {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }

  25% {
    transform: translate(15%, 0);
    opacity: 0.75;
  }

  50% {
    transform: translate(30%, 0);
    opacity: 0.5;
  }

  75% {
    transform: translate(45%, 0);
    opacity: 0.25;
  }

  100% {
    transform: translate(60%, 0);
    opacity: 0;
  }
}

@keyframes insert {
  0% {
    transform: translate(60%, 0);
    opacity: 0;
  }

  25% {
    transform: translate(45%, 0);
    opacity: 0.25;
  }

  50% {
    transform: translate(30%, 0);
    opacity: 0.5;
  }

  75% {
    transform: translate(15%, 0);
    opacity: 0.75;
  }

  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes insert {
  0% {
    transform: translate(60%, 0);
    opacity: 0;
  }

  25% {
    transform: translate(45%, 0);
    opacity: 0.25;
  }

  50% {
    transform: translate(30%, 0);
    opacity: 0.5;
  }

  75% {
    transform: translate(15%, 0);
    opacity: 0.75;
  }

  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes flipDown {
  0% {
    background: @white_deep;
    transform: translate(0, 0);
  }

  25% {
    transform: translate(2.5%, 25%);
  }

  50% {
    transform: translate(5%, 50%);
  }

  75% {
    transform: translate(2.5%, 75%);
  }

  100% {
    background: @white_deep;
    transform: translate(0, 100%);
  }
}

@-webkit-keyframes flipDown {
  0% {
    background: @white_deep;
    transform: translate(0, 0);
  }

  25% {
    transform: translate(2.5%, 25%);
  }

  50% {
    transform: translate(5%, 50%);
  }

  75% {
    transform: translate(2.5%, 75%);
  }

  100% {
    background: @white_deep;
    transform: translate(0, 100%);
  }
}

@keyframes flipUp {
  0% {
    background: @white_deep;
    transform: translate(0, 0);
  }

  25% {
    transform: translate(-2.5%, -25%);
  }

  50% {
    transform: translate(-5%, -50%);
  }

  75% {
    transform: translate(-2.5%, -75%);
  }

  100% {
    background: @white_deep;
    transform: translate(0, -100%);
  }
}

@-webkit-keyframes flipUp {
  0% {
    background: @white_deep;
    transform: translate(0, 0);
  }

  25% {
    transform: translate(-2.5%, -25%);
  }

  50% {
    transform: translate(-5%, -50%);
  }

  75% {
    transform: translate(-2.5%, -75%);
  }

  100% {
    background: @white_deep;
    transform: translate(0, -100%);
  }
}
