@import (reference) 'variables';

.flex-table-container {
  font-size: 14px;
  .flex-table {
    list-style-type: none;
    padding-left: 0;
    // static table item
    .flex-table-item {
      border-bottom: solid 1px @pale_grey;
      align-items: flex-start;
      padding-top: 14px;
      padding-bottom: 14px;
      &:first-child {
        border-top: solid 1px @pale_grey;
      }
      p {
        margin-bottom: 0;
        padding: 5px 0;
      }
      .btn-text {
        border: none;
        padding-right: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-left: 10px;
        text-decoration: none;
        align-self: flex-end;
        height: auto;
      }
      .btn-add {
        margin-left: 0;
        padding-left: 0;

        &::first-letter {
          font-size: 120%;
        }
      }
      .title {
        flex: 1 1 0;
      }
      .data {
        flex: 1 1 0;
        width: auto;
        word-break: break-all;
      }
      a.data {
        &:hover {
          cursor: pointer;
        }
      }
      .buttons {
        flex: 0 1 20%;
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
        justify-content: flex-end;
        &.btn-edit-password {
          flex: 0 1 100%;
          .btn-text {
            width: 50%;
            margin: 0;
            padding-left: 0;
            text-align: left;
          }
        }
      }
      // Reserve space for rows without buttons
      .flex-table-helper {
        flex: 0 1 20%
      }
    }
    // editable table-item
    .flex-table-edit {
      padding: 10px 15px 15px 15px;
      background-color: @pale_grey_opacity_04;
      .edit-body {
        width: 100%;
        .edit-data {
          flex: 1 1 100%;
        }
        input {
          width: 100%;
          margin-bottom: 0;
          margin-top: 0;
        }
        .stylized-select {
          margin: 8px 15px 8px 0;
        }
      }
      .buttons {
        width: 100%;
        padding-top: 15px;
        margin-top: 16px;
        border-top: solid 1px @pale_grey;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        .btn {
          margin-left: 16px;
        }
      }
    }

    .details-logo {
      align-items: center;
    }

    &.control-panel-flex-table {
      .title {
        flex: 1 2 0;
      }
      .data {
        flex: 2 1 0;
      }
    }
  }
}

//.cp-details-content {
//  .flex-table-container .flex-table .flex-table-item.edit-table-item {
//    padding: 0;
//  }
//}

@media (max-width: 767px) {
  .cp-details-content {
    .flex-table-container .flex-table .flex-table-item {
      flex-direction: column;
      position: relative;
      padding: 14px;
      .data {
        padding: 5px 0;
      }
      .title {
        color: @sonic_silver;
        padding: 5px 0;
        margin-bottom: 5px;
      }
      .buttons {
        position: absolute;
        right: 14px;
      }
    }
  }
}

// responsive

@media @tablet_and_desktop {
  .flex-table-container {
    .flex-table {
      &.flex-table-my-profile,
      &.security-table {
        width: 560px;
        li.flex-table-edit .edit-body > .edit-data {
          flex: 1 1 32%;
        }
        .flex-table-item {
          &.flex-table-edit {
            .buttons {
              flex: 0 1 20%;
            }
          }
          .flex-table-helper,
          .buttons {
            flex: 0 1 170px;
          }
        }
        li.flex-table-item {
          .buttons.btn-edit-password {
            flex: 0 1 170px;
          }
        }
      }
      // static table-item
      li.flex-table-item {
        align-items: center;
        .card {
          width: auto;
        }
        .buttons {
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          &.btn-edit-password {
            flex: 0 1 20%;
            .btn-text {
              width: auto;
            }
          }
        }
      }
      // editable table item
      li.flex-table-edit {
        padding-top: 15px;
        .title {
          line-height: normal;
        }
        div p {
          padding-top: 0;
        }
        .edit-body {
          display: flex;
          flex-wrap: wrap;
          > .edit-data {
            flex: 1 1 20%;
            display: flex;
            justify-content: flex-start;
            &.edit-email {
              flex-wrap: wrap;
            }
            &.edit-name > div {
              flex: 1 1 0;
              &:first-child {
                margin-right: 10px;
              }
            }
            input.nolabel:not([type="email"]) {
              margin-top: 0;
            }
          }
          .stylized-select {
            margin-top: 0;
          }
        }
        .text-error {
          padding-left: 10px;
        }
        .error-birthday {
          width: 100%;
          margin-top: -8px;
          padding-left: calc(~'40% + 10px');
        }
        .error-gender {
          width: 100%;
          margin-top: -8px;
          padding-left: calc(~'40% + 10px');
        }
      }
    }
  }

  // IE specific rules
  // we need to explicitly specify width for those elements where we have flex property
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .flex-table-container .control-panel-flex-table.flex-table {
      .data {
        width: 52%;
      }

      .buttons {
        width: 20%;
      }

      .flex-table-item .data {
        word-break: normal;
        text-align: justify;
      }
    }

    .flex-table-container .control-panel-flex-table.flex-table .title,
    form .inline-form > label,
    .form-group-container .form-group label,
    .messages-container .flex-helper{
      width: 28%;
    }

    form .inline-form .form-group-container,
    .form-group-container .form-group .form-control {
      width: 72%;
    }

    form .inline-form .form-group-container .form-group .form-control {
      width: 100%;
    }

    .form-group .text-length-indicator {
      text-align: right;
    }
  }
}

.table-title {
  margin-bottom: 26px;
}

.table {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 14px;

  ul,
  li {
    list-style: none;
  }

  p {
    margin: 0;
  }

  .text-error {
    padding: 1px 0 0 11px;
    font-size: 12px;
  }

  .break-all {
    word-break: break-all;
  }

  &-fixed-width {
    @media @tablet_and_desktop {
      width: 560px;
    }
  }

  &-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    border-bottom: solid 1px @pale_grey;
    padding: 14px 0 10px;
    min-height: 54px;

    @media @tablet_and_desktop {
      align-items: center;
      padding: 13px 0 14px;
    }

    .btn-add {
      padding: 5px 10px 5px 0;
    }

    &-title {
      flex-shrink: 0;
      width: 40%;
      padding-left: 16px;

      @media (min-width: 450px) {
        width: 34%;
      }
    }

    &-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      width: 100%;
      flex-basis: 100%;

      @media @tablet_and_desktop {
        align-items: center;
      }
    }

    &-controls {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;
      padding-right: 16px;

      @media @tablet_and_desktop {
        align-items: center;
        flex-direction: row;
      }


      .btn-remove {
        margin: 0;
        padding: 8px 0 5px 5px;
      }

      .btn-edit {
        margin: 0;
        padding: 4px 0 8px 5px;
      }

      @media @tablet_and_desktop {
        .btn-remove {
          padding: 5px 10px 6px;
        }

        .btn-edit {
          margin-left: 10px;
          padding: 5px 0 6px 10px;
        }
      }
    }

    &-first {
      border-top: 1px solid @pale_grey;
    }

    // Logo row specific styles
    &-logo {
      align-items: center;

      padding: 16px 0 15px;
    }

    // Styles for editing state
    &-edit {
      display: flex;
      flex-direction: column;
      background-color:@pale_grey_opacity_04;
      padding: 0 16px;
      border-bottom: solid 1px @pale_grey;

      &-body {
        display: flex;
        flex-direction: column;
        padding: 17px 0 11px;
        border-bottom: solid 1px @pale_grey;

        @media @tablet_and_desktop {
          flex-direction: row;
          align-items: center;

          padding: 2px 0 7px;
        }

      }

      .stylized-select {
        margin: 10px 0 5px 0;
      }

      &-title {
        @media @tablet_and_desktop {
          width: 33%;
          align-self: flex-start;
          padding-top: 14px;
        }
      }

      &-container {
        display: flex;
        flex-direction: column;
        align-self: flex-start !important;
        justify-content: space-between;
        width: 100%;

        @media @xs_extra_mobile {
          flex-direction: row;
        }
      }

      &-actions {
        display: flex;
        justify-content: flex-end;
        height: 34px;
      }

      &-descriptions {
        width: auto;
        font-size: 12px;
        padding-bottom: 17px;

        @media @xs_extra_mobile {
          width: 233px;
          padding-bottom: 0;
        }
      }

      &-funlife {
        display: flex;
        justify-content: space-between;
      }

      &-controls {
        padding: 15px 0 16px;
        align-self: flex-end;

        button:last-child {
          padding: 8px 14px;
          margin-left: 12px;

          @media @tablet_and_desktop {
            margin-left: 16px;
          }
        }
      }

      &-name {
        justify-content: space-between;
        width: 100%;

        p {
          padding-bottom: 10px;

          @media @tablet_and_desktop {
            padding: 4px 0 0;
            align-self: flex-start;
          }
        }

        &-content {
          @media @tablet_and_desktop {
            display: flex;
          }
        }

        &-body {
          padding: 12px 0;
        }

        .input-row {
          padding-bottom: 16px;
          margin-bottom: 0;

          &:last-child {
            padding-bottom: 5px;
          }

          @media @tablet_and_desktop {
            padding-bottom: 0;

            &:last-child {
              padding-bottom: 0;
              margin-left: 10px;
            }
          }

          input {
            width: 168px;
            padding: 4px 10px;
            margin: 0;

            @media @tablet_and_desktop {
              padding: 0 10px;
            }
          }
        }
      }
    }

    &-user-id {
      .table-row-title {
        text-transform: capitalize;
      }
    }

    // styles for list items with 2 elements justified to the sides on desktop
    &-email-change-confirm {
      flex-direction: column;
      align-items: flex-start;
      padding: 9px 0 11px;

      @media @tablet_and_desktop {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .table-row-controls {
        flex-direction: row;
        padding-top: 11px;

        @media @tablet_and_desktop {
          padding-top: 0;
        }

        .btn-resend {
          padding: 9px 15px;
        }

        .btn-cancel {
          padding: 9px 0 9px 15px;
        }
      }

      .table-row-title,
      .table-row-controls {
        display: flex;
        width: 100%;

        @media @tablet_and_desktop {
          width: auto;
          max-width: 49%;
        }
      }

      .table-row-controls {
        justify-content: flex-end;
      }

      .table-row-title {
        line-height: 1.14;
        padding-top: 11px;

        @media @tablet_and_desktop {
          padding-top: 0;
        }
      }
    }

    // styles for editing state
    // when current and new values are displayed in column
    // and current value is displayed before input
    &-edit-email {
      &-body {
        padding: 15px 0 0;
        align-items: flex-start;
      }

      p {
        padding-top: 0;
      }

      &-value {
        width: 100%;
        word-break: break-all;
        margin-bottom: 16px;

        input[type='email'] {
          width: 100%;
          margin-bottom: 0;
        }
      }
    }
  }

  .table-row-edit-controls.table-row-edit-container .table-row-edit-actions {
    padding: 0;
  }

  .table-row-value, .table-row-controls, .not-defined {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
}
