@import 'partials/variables';

.vendor-logo {
  border: 1px solid @pale_grey;
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  .logo-container {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: center / cover no-repeat;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
  }

  &.no-border {
    border: none;
  }
}

.vendor-logo-small {
  height: 48px;
  width: 48px;
}

.vendor-logo-medium {
  height: 70px;
  width: 70px;
}

.vendor-logo-large {
  height: 176px;
  width: 176px;
  max-width: 100%;
  max-height: 100%;
}

@media @desktop {
  .vendor-logo {
    border-color: @limed_spruce_opacity_01;
  }
}
