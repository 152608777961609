@import (reference) 'partials/variables';

@keyframes move-up {
  from {
    transform: translateY(0px);
    box-shadow: none;
  }
  to {
    transform: translateY(-4px);
    box-shadow: 0 6px 25px 0 @black_pearl_opacity_012;
  }
}

@keyframes move-down {
  from {
    transform: translateY(-4px);
    box-shadow: 0 6px 25px 0 @black_pearl_opacity_012;
  }
  to {
    transform: translateY(0px);
    box-shadow: none;
  }
}

.cta-button-mixin() {
  height: 32px;
  padding: 8px 16px;
  border-radius: 4px;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: .8px;
  line-height: 14.4px;
}

.cta-link-mixin() {
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.8px;
  line-height: 16px;
}

.checkmark-mixin() {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  cursor: pointer;
  height: 16px;
  width: 16px;
  max-height: 16px;
  max-width: 16px;
  min-height: 16px;
  min-width: 16px;
  padding: 2px;
  text-align: center;
  margin-top: 1px;
}

.checkbox-checked-mixin() {
  background-image: url('/assets/checkmark.png');
  background-repeat: no-repeat;
  background-size: 14px;
}

.background-color-opacity-mixin(@opacity) {
  background-color: rgba(230, 232, 237, @opacity);
}

.submenu-mixin() {
  padding: 7px 0;
  background-color: @white_deep;
  box-shadow: 0 2px 6px 0 @black_opacity_01;
  list-style: none;
  position: absolute;
  top: 40px;
  right: 0;
  border-radius: 4px;
  border: 1px solid @pale_grey;
  display: none;
  min-width: 177px;
  text-transform: none;

  &.submenu-expanded {
    display: block;
    z-index: 1;
  }

  li {
    display: block;
    width: 100%;
    text-align: left;

    a {
      padding: 0 16px;
      font-size: 14px;
      font-weight: normal;
      line-height: 2.86;
      letter-spacing: normal;
      text-align: left;
      color: @limed_spruce_dark;
      white-space: nowrap;
      cursor: pointer;
      border: 0;
      display: block;
      width: 100%;

      &:hover {
        transition: background-color .15s linear;
        .background-color-opacity-mixin(0.5);
        text-decoration: none;
      }

      &:active {
        transition: background-color .15s linear;
        .background-color-opacity-mixin(0.65);
        text-decoration: none;
      }
    }
  }
}

.clear-link-mixin() {
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

.width-mixin-on-list-item(){
  width: calc(~'100% + 40px');
  margin-left: -20px;
  padding: 23px 20px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 20px;
    height: 2px;
    width: calc(~'100% - 40px');
    border-top: 1px solid @pale_grey
  }

  &.last::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 20px;
    height: 2px;
    width: calc(~'100% - 40px');
    border-top: 1px solid @pale_grey
  }
}

.hover-mixin-on-list-item(){

  &:hover {
    border-top: none;
    -moz-box-shadow: 0 6px 25px 0 rgba(3, 8, 33, 0.12);
    -webkit-box-shadow: 0 6px 25px 0 rgba(3, 8, 33, 0.12);
    box-shadow: 0 6px 25px 0 rgba(3, 8, 33, 0.12);
    border-radius: 4px;
    cursor: pointer;
    transition: 0.15s ease-in-out;
  }

  &:hover::before,
  &:hover::after {
    transition: 0.15s ease-in-out;
    display: none;
  }
}

.card-hover-mixin() {
  @media (hover: hover), screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { // + fix for IE
    cursor: pointer;

    .card-wrapper {
      box-sizing: content-box;
      position: relative;
    }

    &:hover {
      .card-wrapper {
        cursor: pointer;
        border: 1px solid transparent;
        animation: move-up 250ms ease forwards;

        .save-buttons {
          bottom: 16px;
        }
      }
    }

    .hover-out {
      // need to use @keyframes here not transition because object-fit property
      // that is applied to card image breaks smooth transition effect
      animation: move-down 250ms ease forwards;
    }

    &:active {
      .card-wrapper {
        animation-play-state: paused;
      }
    }
  }
}

.banner-border-mixin() {
  border: 1px solid @pale_grey;
  border-radius: 4px;
}

.homepage-banner-padding-mixin() {
  padding: 35px 0 31px 47px;
}

.no-matches-found {
  margin-top: 40px;

  height: 71px;
  border-bottom: 1px solid @pale_grey;
  border-top: 1px solid @pale_grey;

  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    color: @color_jet;
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: normal;
    margin: 0;
  }
}

.disclaimer-white-icon-mixin() {
  svg {
    path {
      fill: @white;
    }
    circle {
      fill: @black_opacity_02;
    }
  }

  &:hover {
    svg circle {
      fill: @black_opacity_03;
    }
  }
}
