@import (reference) "partials/variables";
@import (reference) "partials/mixins";

@xs_exceptions: ~"( min-width: 450px )";

.confirmation-modal {
  /* Display & Box Model */
  display: flex !important; // Just need to re-write bootstrap styles for own class
  align-items: center;
  margin: 0;

  .modal-dialog {
    /* Display & Box Model */
    margin: 0 auto;

    .modal-content {
      /* Display & Box Model */
      width: 280px;
      height: 255px;
      padding: 0;

      app-unsubscribe-confirmation-modal {
        /* Display & Box Model */
        height: 100%;

        .modal-body-container {
          /* Display & Box Model */
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding: 16px;

          &__title {
            /* Text */
            font-weight: bold;
            font-size: 16px;
            text-align: center;
            /* Color */
            color: @limed_spruce;
          }

          &__close {
            /* Display & Box Model */
            border: none;
            width: 30px;
            position: absolute;
            top: 13px;
            right: 22px;
            /* Color */
            background: none;
            color: @limed_spruce;
            /* Text */
            outline: none;
            cursor: pointer;
            opacity: 0.5;
          }

          &__subtitle {
            /* Display & Box Model */
            padding-bottom: 0;
            margin-bottom: 24px;
            /* Color */
            color: @limed_spruce;
            /* Text */
            text-align: center;
            font-size: 12px;
            line-height: 16px;
          }

          &__unsubscribe {
            margin-bottom: 24px;
            height: 40px;
            font-size: 16px;
            letter-spacing: 1px;
          }

          &__cancel {
            /* Display & Box Model */
            margin-bottom: 8px;
            height: 40px;
            /* Text */
            font-size: 16px;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
}

@keyframes modal-fade {
  from {
    top: -50px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

.modal {
  top: -100px;
  animation: ease-in-out 0.3s modal-fade;

  &.show {
    top: 0;
  }

  .modal-body {
    &:focus {
      outline: none;
    }
  }
}

.unsubscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 28px;

  &-img {
    &__item {
      width: 100px;
      height: 84px;
    }
  }

  &-title {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 16px;

    &__primary {
      width: 225px;
      font-weight: 900;
      font-size: 18px;
      line-height: 26px;
      color: @limed_spruce;
      margin: 24px 0 8px 0;
    }

    &__secondary {
      font-size: 14px;
      line-height: 21px;
      width: 280px;
      text-align: center;
      color: @limed_spruce;
    }
  }

  &-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 24px 0 84px 0;

    &__content {
      text-align: center;
      line-height: 28px;
      font-size: 14px;
      color: @sonic_silver;
      width: 205px;
      margin-bottom: 0;

      a {
        border-bottom: 1px solid;
        padding-bottom: 0px;
        line-height: 1.31;
        cursor: pointer;
      }
    }
  }
}

@media @tablet_and_desktop {

  .confirmation-modal {
    .modal-dialog {
      .modal-content {
        /* Display & Box Model */
        width: 316px;
        height: 275px;

        app-unsubscribe-confirmation-modal {

          /* Display & Box Model */
          .modal-body-container {
            padding: 24px;

            &__title {
              /* Text */
              font-weight: 500;
              font-size: 24px;
              /* Display & Box Model */
              padding-bottom: 18px;
              margin: 0;
            }

            &__close {
              /* Display & Box Model */
              top: 13px;
              right: 13px;
            }

            &__subtitle {
              /* Text */
              text-align: center;
              font-size: 14px;
              line-height: 16px;
              /* Display & Box Model */
              padding-bottom: 0;
              margin-bottom: 25px;
            }

            &__unsubscribe {
              /* Display & Box Model */
              margin-bottom: 16px;
              height: 40px;
            }

            &__cancel {
              /* Display & Box Model */
              margin-bottom: 0;
              height: 40px;
            }
          }
        }
      }
    }
  }

  .unsubscribe {
    &-img {
      &__item {
        width: 142px;
        height: 120px;
      }
    }

    &-title {
      margin-bottom: 32px;

      &__primary {
        width: auto;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        margin: 32px 0 8px 0;
      }

      &__secondary {
        font-size: 16px;
        line-height: 24px;
        width: 499px;
      }
    }
  }
}

@media @landscape_and_desktop {
  .unsubscribe {
    padding-top: 175px;

    &-footer {
      &__content {
        width: inherit;
        line-height: 25px;
        font-size: 14px;
      }
    }
  }
}