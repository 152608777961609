.info-page {
  margin: 0 auto;
  padding-top: 80px;
  text-align: center;

  .logo {
    text-align: center;
  }

  h1 {
    margin-top: 33px;
    font-size: 32px;
    line-height: 1.4;
    padding-left: 20px;
    padding-right: 20px;
  }

  a {
    width: 149px;
    margin: 51px auto 0 auto;
    display: block;
  }
}

@media (min-width: 768px) {
  .info-page {
    padding-top: 188px;

    h1 {
      font-size: 40px;
    }
  }
}