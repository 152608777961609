// The standard modal styles
.modal {

  &.vault-otp-modal {
    display: flex !important; // important is need to overwrite d-block: block!important style
    align-items: center;
    margin: 0;

    .modal-dialog {
      margin: 0 auto;

      .modal-content {
        min-width: 314px;
        width: 314px;
        padding: 0;
      }
    }
  }

  &.offer-otp-modal {
    display: flex !important; // important is need to overwrite d-block: block!important style
    align-items: center;
    margin: 0;

    .modal-dialog {
      margin: 0 auto;

      .modal-content {
        background-color: #FFFAE6;
        min-width: 400px;
        width: 400px;
        padding: 24px 32px 32px 32px;
      }
    }
  }

  &.edit-notification-modal {
    display: flex !important; // important is need to overwrite d-block: block!important style
    align-items: center;
    margin: 0;

    .modal-dialog {
      height: 572px;

      .modal-content {
        width: 556px;
      }
    }
  }

  // The modal style with minimal padding, used for confimation modals
  .modal-content {
    padding: 16px;
    min-width: 314px;
    min-height: 154px;
    margin: 0 auto;
    border: none;
  }

  .modal-header {
    min-height: 35px;
    padding: 0;
    margin-bottom: 8px;
    border: 0;
    align-items: center;

    h4 {
      font-size: 20px;
      line-height: 1;
    }

    .close {
      position: absolute;
      top: 13px;
      right: 13px;
      padding: 0;
      font-size: 2.05rem;
      font-weight: 400;
      opacity: 0.4;

      span {
        display: flex;
        height: 24px;
        width: 24px;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .modal-body {
    padding: 0;
    margin-bottom: 0;
  }

  .modal-footer {
    padding: 0;
  }

  &.form-modal {
    .modal-dialog {
      max-width: 358px;
      margin-left: auto;
      margin-right: auto;
    }

    .modal-header {
      h4 {
        padding-top: 24px;
        font-size: 24px;
      }
    }

    .modal-body {
      margin-bottom: 13px;
    }
  }

  &.confirm-modal {
    .modal-dialog {
      max-width: 314px;
      margin-left: auto;
      margin-right: auto;
    }

    .modal-header {
      padding: 0;
    }

    .modal-body {
      padding: 0;
      margin-bottom: 0;

      p {
        font-size: 14px;
        line-height: 1.43;
      }
    }

    .modal-footer {
      padding: 4px 14px;
      justify-content: space-between;
      border-top: none;

      .btn {
        min-width: 114px;
        font-size: 14px;
        line-height: 1.6;
        letter-spacing: 0.8px;
      }
    }
  }

  &.contact-alert-modal {
    .modal-dialog {
      max-width: 358px;
      margin-right: auto;
      margin-left: auto;
    }

    .modal-content {
      width: 100%;
      min-width: auto;
      min-height: auto;
      padding: 0px;
      margin: 0;
    }

    .modal-header {
      justify-content: center;
      padding: 20px 24px 0 24px;

      h4 {
        padding-top: 24px;
        font-size: 24px;
      }

      .close {
        position: absolute;
        top: 8px;
        right: 20px;
        padding: 0;
        font-size: 1.9rem;
        font-weight: 400;

        span {
          width: auto;
          height: auto;
        }
      }
    }

    .modal-body {
      padding: 14px 24px;

      p {
        font-size: 14px;
        line-height: 1.43;
      }
    }

    .modal-footer {
      justify-content: center;
      padding: 14px 24px;
      padding-bottom: 24px;
      border-top: 0;

      .btn {
        min-width: 82px;
        font-size: 12px;
        letter-spacing: 0.8px;
        line-height: 1.33;
      }

      >:not(:last-child) {
        margin-right: 5px;
      }
    }
  }

  &.coupon-modal {
    .modal-content {
      height: auto;
      width: 280px;
    }
  }

  // Let the owner of this modal position things manually- no padding
  &.custom-modal {

    .modal-content {
      padding: 0 24px;
    }

    .modal-header {
      min-height: 42px;
      border-bottom: 0;
      padding: 0;
      display: flex;
      margin-bottom: 14px;

      h4 {
        font-size: 24px;
        line-height: 1;
      }
    }

    .modal-body {
      padding: 0;
      margin-bottom: 28px;

      p {
        font-size: 16px;
        line-height: 1.43;
      }
    }

    .modal-footer {
      padding: 0 0 28px 0;
      justify-content: center;
      border-top: 0;

      >:not(:last-child) {
        margin-right: 5px;
      }
    }
  }

  &.create-password-modal {
    .modal-content {
      max-width: 298px;
      min-width: 298px;
    }
  }

}

.widget-iframe-modal {
  .modal-content {
    padding: 0px;
  }
}


@media (min-width: 768px) {
  .modal .modal-dialog {
    max-width: 599px;

    &.modal-sm {
      max-width: 350px;

      h4 {
        padding-bottom: 0;
      }
    }

    &.modal-lg {
      max-width: 694px;
    }
  }
  .modal.coupon-modal {
    .modal-content {
      height: auto;
      width: 316px;
    }
  }

  .modal.widget-iframe-modal .modal-dialog {
    max-width: 676px;
    width: 676px;
  }

  .widget-iframe-modal {
    .modal-content {
      width: 676px;
      height: 888px;
    }
  }
}

@media @landscape_and_desktop {
  .modal.widget-iframe-modal .modal-dialog {
    max-width: 808px;
    width: 808px;
  }

  .widget-iframe-modal {
    .modal-content {
      width: 808px;
      height: 888px;
    }
  }
}

@media @mobile_exception_case {
  .modal-dialog .modal-content {
    width: 280px;
    min-height: 154px;
    min-width: 280px;
    margin: 0 auto;
  }

  .modal.offer-otp-modal {
    .modal-dialog {
      .modal-content {
        min-width: 280px !important;
        width: 280px !important;
        padding: 16px !important ;
      }
    }
  }



  .modal.widget-iframe-modal {
    overflow: hidden;
   .modal-dialog {
      margin: 0;
      max-width: 100%;
      width: 100%;
      height: 100%;

      .modal-content {
        width: 100%;
        height: 100%;
      }
    }
  }
}
