@media @mobile_exception_case {
  .hide-xs {
    display: none !important;
  }
}

@media (max-width: 1023px) {
  .hide-lt-1024 {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .hide-gt-1024 {
    display: none !important;
  }
}

@media @tablet_and_desktop {
  .hide-gt-xs {
    display: none !important;
  }
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }

  .modal-dialog {
    padding: 0 20px;
  }
}

@media (min-width: 576px) {
  .modal-sm {
    max-width: 350px;
  }
}

// Prevent iOS zoom on field activate, for this max-width is appropriate
@media screen and (max-width: 767px) {
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 14px;
  }
}

