@import (reference) "partials/variables";

input.widget-date-input.ngb-datepicker::-webkit-calendar-picker-indicator {
  background: transparent url("/assets/icons/ic-calendar.svg") left 0 top 50% no-repeat;
  opacity: 0;
  position: absolute;
  margin-left: -35px;
  margin-top: 2px;
  width: calc(100% - 5px);
  height: 100%;
}

input.widget-date-input.ngb-datepicker::-webkit-datetime-edit {
  flex: none;
}

input.widget-date-input.ngb-datepicker::-ms-clear {
  display: none;
}

input.widget-date-input {
  border-radius: 4px;
}

.widget-body {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: -20px;
}

@media @tablet_and_desktop {
  .widget-body {
    margin-top: 2px;
  }
}

@media @landscape_and_desktop {
  .widget-body {
    margin-top: -23px;
  }
}

@media @desktop {
  .widget-body {
    margin-top: 2px;
  }
}

.widget-label {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: @sonic_silver;
}

input.widget-input {
  height: 40px;
  padding: 12px 12px 13px;
  border: 1px solid @water_grey;
  margin: 0;
  color: @limed_spruce;

  &::placeholder {
    color: @limed_spruce_opacity_05;
    opacity: 1;
  }

  &::-webkit-input-placeholder {
    color: @limed_spruce_opacity_05;
    opacity: 1;
  }

  &::-moz-input-placeholder {
    color: @limed_spruce_opacity_05;
    opacity: 1;
  }

  &:-moz-input-placeholder {
    color: @limed_spruce_opacity_05;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: @limed_spruce_opacity_05;
    opacity: 1;
  }
}

.widget-form {
  display: flex;
  flex-direction: column;
}

.mini-widget {
  display: flex;
  flex-direction: column;
}

input.large-widget-datepickers__check-in--input,
input.mini-widget-datepickers__check-in--input,
input.large-widget-datepickers__check-out--input,
input.mini-widget-datepickers__check-out--input,
input.mini-widget-pick-up__date--input,
input.mini-widget-drop-off__date--input {
  padding-left: 35px;
  background: white url("/assets/icons/ic-calendar.svg") left 9px top 53% no-repeat;
}

input.search-widget-datepickers__check-in--input,
input.search-widget-datepickers__check-out--input {
  padding-left: 35px;
  background: white url("/assets/icons/ic-calendar.svg") left 12px top 53% no-repeat;
}

.mini-widget {
  &-autocomplete {
    &__label {
      margin-bottom: 5px;
    }
  }
}
