/* You can add global styles to this file, and also import other style files */
@import 'shared/modals';
@import 'partials/variables';
@import 'partials/mixins';
@import 'partials/reset';
@import 'partials/typography';
@import 'partials/buttons';
@import 'partials/tables';
@import 'partials/modals';
@import 'partials/forms';
@import 'partials/responsive';
@import 'partials/widgets';
@import 'partials/flex';
@import 'error-page';
@import 'sign-in-styles';
@import 'logo';
@import 'partials/empty-lists';
@import 'partials/logos';
@import 'partials/views-counter';
@import 'partials/offers-animate';
@import '../app/widgets/styles/widgets';
@import '../app/unsubscribe/styles/unsubscribe-path';

@import "~mapbox-gl/dist/mapbox-gl.css";

body {
  background-color: @white_deep;
  font-family: "Lato", sans-serif;
  overflow-x: hidden;
}

hr {
  opacity: 0.1;
}

header .logo {

  a {
    display: block;
  }
}

@media screen and (min-width: 1024px) {
  .home-page-item {
    padding: 0 9px;
    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
    }
  }
}

@media screen and (max-width: 1023px) {
  .home-page-item {
    padding: 8px;
    &:nth-child(odd) {
      padding-left: 0;
    }
    &:nth-child(even) {
      padding-right: 0;
    }
  }
}

app-header {
  z-index: 2000000001;
}

a:hover {
  text-decoration: none;
}

.content {
  flex: 1 0 auto;
}

.content2 {
  margin-top: 24px;
}

.confirm-email .info {
  text-align: center;
  padding-bottom: 24px;
  font-size: 14px;
  line-height: 1.29;
  margin-top: -14px;
  p {
    margin-bottom: 0;
  }
  &.reset-password-info {
    margin-top: 0;
  }
}

.confirm-email .details {
  border-top: 1px solid @pale_grey;
  padding-top: 30px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  span {
    display: inline-block;
    padding-top: 12px;
  }

  a.btn {
    font-size: 14px;
    font-weight: 900;
    padding: 11px 15px;
  }
}

.full-width {
  width: 100%;
}

.relative {
  position: relative;
}

.remember-me {
  margin-top: 12px;
  font-size: 13px;

  label {
    display: flex;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 0;

    .checkbox {
      position: absolute;
      opacity: 0;
      width: 10px;
      cursor: pointer;
    }

    .checkmark {
      .checkmark-mixin();
    }

    .checkbox:checked ~ .checkmark {
      .checkbox-checked-mixin();
    }

    p {
      padding-left: 10px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
    }
  }
}

.container {
  padding-right: 20px;
  padding-left: 20px;
}

// Cards
.card-link + .card-link {
  margin-left: 16px;
}

// Heavy styling. Use those CSS-classes to override specific styles.
.error-message {
  color: @error-text !important;
}
.text-right {
  text-align: right !important;
}
.border-bottom-none {
  border-bottom: 0 !important;
}
.position-relative {
  position: relative !important;
}

.form-upgrade-version {
  .popover {
    max-width: 331px;
    box-shadow: 0 2px 6px 0 @black_opacity_01;
  }

  .popover-body {
    font-family: "Lato", sans-serif;
    line-height: 1.43;
    font-size: 14px;
    color: @limed_spruce;

    padding: 20px 20px 0 20px;

    p {
      margin-bottom: 15px;
    }
  }
}

.carousel-indicators {
  li {
    cursor: pointer;
  }
}

.loader-container {
  position: relative;
}

.loader-container app-spinner {
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  justify-content: center;
  align-items: center;
}

.loader-container [hidden] {
  display: block !important;
  opacity: 0;
  visibility: hidden;
}

.dropdown-menu {
  width: 100%;
  max-height: 220px;
  overflow-y: auto;
  .dropdown-item {
    color: @limed_spruce;
    font-size: 14px;
    padding: 10px;
    &:hover,
    &:active,
    &.active {
      background-color: @pale_grey_opacity_05;
    }
  }
}

// Policy microsite global styling
.policies {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: @limed_spruce;
  }
  .policy-content {
    h2 {
      font-size: 22px;
      font-weight: bold;
      margin-top: 30px;
    }
    h3 {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.5;
      margin-top: 28px;
    }
    section {
      margin-bottom: 30px;
    }
    p,
    ul,
    ol {
      margin-bottom: 16px;
    }
    mark {
      background-color: @corn_silk;
    }
    a {
      color: @bondi_blue;
      text-decoration: none;
      border-bottom: 2px solid @bondi_blue;
      padding-bottom: 1px;
    }
    li {
      position: relative;
      list-style-type: none;
      padding-left: 32px;
      margin-bottom: 8px;
      &::before {
        content: "•";
        color: @cobalt_blue;
        position: absolute;
        left: 16px;
      }
    }
    ol {
      list-style: none;
      counter-reset: li;
      li {
        counter-increment: li;
        &::before {
          font-weight: 700;
          content: counter(li) ". ";
          color: @cobalt_blue;
          position: absolute;
          left: 16px;
        }
      }
    }
  }
}

.text-overflow {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.visibility-hidden {
  visibility: hidden;
}

@media @landscape_and_desktop {
  body.modal-open {
    app-header {
      header {
        .header-primary {
          margin-right: 17px;
        }

        .header-secondary {
          margin-right: calc((100vw - 944px) / 2 + 8.5px);
        }
      }
    }
  }
}

@media @desktop {
  body.modal-open {
    app-header {
      header {
        .header-secondary {
          margin-right: calc((100vw - 1136px) / 2 + 8.5px);
        }
      }
    }
  }
}

@media @wide_desktop {
  body.modal-open {
    app-header {
      header {
        .header-secondary {
          margin-right: calc((100vw - 1406px) / 2 + 8.5px);
        }
      }
    }
  }
}

.submenu {
  .submenu-mixin();

  .submenu-icon {
    padding-right: 5px;
    position: relative;
    top: -1px;
  }
}

// Control Panel global styling
.control-panel {
  .container {
    padding: 0 20px;
    max-width: 100%;
  }

  @media @tablet_and_desktop {
    .container {
      padding: 0 40px;
    }
  }

  .control-panel-heading {
    margin: 22px 0 6px;
    font-weight: 400;
    font-size: 18px;
    color: @limed_spruce;

    @media @tablet_and_desktop {
      margin: 36px 0 26px;
      font-size: 24px;
    }
  }
  .card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media @tablet_and_desktop {
    .card-container {
      justify-content: space-between;
      margin: -8px -8px 0;
    }
  }
}

@media screen and (min-width: 768px) {
  .main-container {
    max-width: 700px;
    padding-right: 0;
    padding-left: 0;
  }
}

@media screen and (min-width: 1024px) {
  .main-container {
    max-width: 944px;
  }

  .desktop-header {
    max-width: calc(100% - 48px);
  }
}

@media screen and (min-width: 1280px) {
  .main-container {
    max-width: 1136px;
  }
}

@media @wide_desktop {
  .wide-container {
    max-width: 1406px;
  }
}

// !important flag needs for overwriting bootstrap styles.
ngb-modal-backdrop,
ngb-modal-window {
  z-index: 2000000002 !important;
}

app-hotels-form,
app-rental-car-form,
app-tickets-form {
  app-select {
     // Override only for appropriate components
    margin: 0 !important;
    .stylized-select {
      select {
        width: 100%;
        height: 40px;
        &::placeholder {
          color: @limed_spruce_opacity_05;
          opacity: 1;
        }
      }

      .select-arrow {
        top: 11px;
        right: 14px;
      }
    }
  }
}

.pac-container {
  .pac-item {
    font-family: Lato,sans-serif;
    font-size: 12px;
  }

  .pac-item-query {
    font-family: Lato,sans-serif;
    font-size: 15px;
  }
}

// Override app-select styles for rooms-and-guests styles
app-rooms-and-guests {
  app-select {
    margin: 0 !important;
    .kids {
      select {
        width: 84px;
        height: 32px;
      }

      svg {
        top: 7px !important;
        right: 6px !important;
      }
    }
  }
}

app-tickets-search-result {
  app-share-button {
    .btn.btn-icon.btn-share {
      padding: 0;
      top: auto;
      left: auto;
      position: static;
      height: auto;
      width: auto;
    }
  }
}

// Override for ngb-tooltip exception for tickets widget result
@media (min-width: 768px) and (max-width: 880px) {
  ngb-tooltip-window.tickets-widget-tooltip {
    left: -55px !important;
  }
}

// Override for ngb-tooltip exception for tickets widget result
@media (min-width: 1024px) and (max-width: 1100px) {
  ngb-tooltip-window.tickets-widget-tooltip {
    left: -35px !important;
  }
}

// Override for ngb-tooltip exception for tickets widget result
@media (min-width: 1280px) and (max-width: 1340px) {
  ngb-tooltip-window.tickets-widget-tooltip {
    left: -37px !important;
  }
}

.feedback-button {
  left: calc(100% - 35px) !important; // override
}

.pinned-zone {
  &-container {
    app-tdd-scroll,
    app-cards-fixed,
    app-cards-scroll {
      .category-item-container {
        margin: 0 auto !important; // Override
      }
    }

    .horizontal-scroll {
      padding-bottom: 0 !important; // Override
    }
  }
}

// ------------- NGB Datepicker -------------

ngb-datepicker.dropdown-menu {
  max-height: initial;
  width: 237px;
  margin-left: -0.55px;
}

.ngb-datepicker {
  font-size: 14px;
  color: @limed_spruce;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  ngb-datepicker.dropdown-menu {
    max-height: 290px;
  }
}

app-share-button.btn-share-product {
  button.btn.btn-icon {
    padding: 0;
    height: 19px;
    width: 18px;
    top: 0;
    left: 3px;
  }
}

app-browse-offers {
  app-side-tabs {
    .navigation {
      .nav-item-wrapper {
        margin: 0 !important; // Override common styles;
      }
    }
  }
}

app-my-profile {
  app-side-tabs {
    .navigation {
      .nav-active {
        background-color: var(--theme-primary-color-opacity-10);
      }
    }
  }
}

.ngb-dp-weekday {
  font-style: normal !important; // ngb override
  color: var(--theme-secondary-color) !important; // ngb override
}

.btn-light.bg-primary {
  background: var(--theme-primary-color) !important; // ngb override
}

.ngb-dp-arrow {
  .btn:hover {
    color: var(--theme-primary-color);
  }
}
