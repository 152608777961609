.logo {
  img {
    max-width: 148px;
    max-height: 31px;
  }

  .big-logo {
    max-width: 176px;
    max-height: 72px;
  }
}